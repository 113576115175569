// EasiTrack colors

// primary

$light-primary: #2e69b3;
$light-primary-contrast: #ffffff;

$dark-primary: #2e69b3;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #cf6e2d;
$light-accent-contrast: #ffffff;

$dark-accent: #cf6e2d;
$dark-accent-contrast: #ffffff;

// common

$page-background: #2e69b32f;
